<template>
  <div class="recommned-preview">
    <detail
      :visible.sync="visible"
      :rid="rid"
      :edit="false"
      :breadcrumbVisible="false"
      :toolBarVisible="false"
      :canModify="false"
      :showProductList="true"
    >
      <template>
        <a-button type="primary" @click="handleBack">{{ $t("back") }}</a-button>
      </template>
      <template v-slot:btn-group="slot">
        <a-button class="btn" @click="slot.export">
          <i class="iconfont icon-huixingzhen"></i>
          {{ $t("export") }}
        </a-button>
      </template>
    </detail>
  </div>
</template>

<script>
import detail from "../recommend/detail.vue";
export default {
  data() {
    return {
      rid: 0,
      visible: false,
    };
  },
  methods: {
    handleBack() {
      this.$router.push({ path: "/lists", query: { type: 4 } });
    },
  },
  created() {
    const { id } = this.$route.params;
    if (id) {
      this.rid = id;
      this.visible = true;
    }
  },
  components: {
    detail,
  },
};
</script>

<style lang="less" scoped>
.recommned-preview {
  height: calc(100vh - 52px);
  overflow: hidden;
  ::v-deep .recommend .content .container {
    height: calc(100vh - 52px - 52px);
  }
  ::v-deep {
    .recommend .content {
      height: unset;
    }
  }
}
</style>
