<template>
  <div v-if="show" class="customer-detail-modal">
    <div class="mask"></div>
    <div class="customer-detail body">
      <slot name="header">
        <div class="header">
          <div class="sell-volume"></div>
          <div class="settlement">
            <i class="el-icon-close" @click="close"></i>
          </div>
        </div>
      </slot>
      <template v-if="!edit">
        <div class="company-name">
          {{ customer.enterprise }}
          <div class="contact">
            <span>
              {{ customer.last_name + customer.first_name }}
            </span>
          </div>
        </div>
        <div class="info">
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label">{{ $t("address") }}</span>
              <span>{{ customer.street }}</span>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("tel") }}</span>
              <span class="text-primary">{{ customer.tel }}</span>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label"></span>
              <span>{{ customer.city }}</span>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("mobile") }}</span>
              <span class="text-primary">{{ customer.user_mobile }}</span>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label"></span>
              <span>{{ customer.province }}</span>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">email</span>
              <span class="text-primary">{{ customer.user_email }}</span>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label"></span>
              <span>{{ customer.country }}</span>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("site") }}</span>
              <span class="text-primary">{{ customer.site }}</span>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label">{{ $t("tax") }}</span>
              <span>{{ customer.vat_number }}</span>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("tag") }}</span>
              <span>
                <a-tag color="#108ee9" v-if="customer.tag">
                  {{ customer.tag }}
                </a-tag>
              </span>
            </a-col>
          </a-row>
        </div>
        <slot name="footer"></slot>
      </template>
      <template v-else>
        <div class="company-name">
          <a-input
            v-model="mCustomer.enterprise"
            :defalutValue="customer.enterprise"
            autocomplete="off"
            :placeholder="$t('typeCompanyName')"
          ></a-input>
          <div class="contact">
            <a-input
              v-model="mCustomer.first_name"
              autocomplete="off"
              :placeholder="$t('typeFirstName')"
            ></a-input>
            <a-input
              v-model="mCustomer.last_name"
              autocomplete="off"
              :placeholder="$t('typeLastName')"
            ></a-input>
          </div>
        </div>
        <div class="info edit">
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label">{{ $t("address") }}</span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeStreet')"
                :defalutValue="customer.street || ''"
                v-model="mCustomer.street"
              ></a-input>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("tel") }}</span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeTel')"
                :defalutValue="customer.tel"
                v-model="mCustomer.tel"
              ></a-input>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label"></span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeCity')"
                :defalutValue="customer.city || ''"
                v-model="mCustomer.city"
              ></a-input>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("mobile") }}</span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeMobile')"
                :defalutValue="customer.user_mobile"
                v-model="mCustomer.user_mobile"
              ></a-input>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label"></span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeProvince')"
                :defalutValue="customer.province || ''"
                v-model="mCustomer.province"
              ></a-input>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">email</span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeEmail')"
                :defalutValue="customer.user_email"
                v-model="mCustomer.user_email"
              ></a-input>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label"></span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeCountry')"
                :defalutValue="customer.country || ''"
                v-model="mCustomer.country"
              ></a-input>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("site") }}</span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeSite')"
                :defalutValue="customer.site"
                v-model="mCustomer.site"
              ></a-input>
            </a-col>
          </a-row>
          <a-row class="row" :gutter="16">
            <a-col class="col" span="12">
              <span class="label">{{ $t("tax") }}</span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeTax')"
                :defalutValue="customer.vat_number"
                v-model="mCustomer.vat_number"
              ></a-input>
            </a-col>
            <a-col class="col" span="12">
              <span class="label">{{ $t("tag") }}</span>
              <a-input
                autocomplete="off"
                :placeholder="$t('typeTag')"
                :defalutValue="customer.tag"
                v-model="mCustomer.tag"
              ></a-input>
            </a-col>
          </a-row>
          <a-row
            style="margin-top: 20px; margin-bottom: 10px"
            type="flex"
            justify="end"
          >
            <a-col :span="6">
              <a-checkbox v-model="isCreateAccount" @change="hangdleChange">一键生成账号</a-checkbox>
            </a-col>
          </a-row>
          <a-row type="flex" justify="end">
            <a-col :span="6">
              <a-button type="primary" @click="submit">提交</a-button>
            </a-col>
          </a-row>
        </div>
      </template>
      <div class="customer-avatar" v-if="edit">
        <a-upload
          name="avatar"
          accept="image/png,image/jpeg"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :customRequest="customRequest"
          @change="handleImgChange"
        >
          <img class="avatar" v-if="imageUrl" :src="imageUrl" alt="avatar" />
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">Upload</div>
          </div>
        </a-upload>
      </div>
      <div class="customer-avatar border" v-else>
        <img
          class="avatar"
          :src="
            customer.head_img ||
            'https://shoe-1303249748.cos.ap-shanghai.myqcloud.com/shoe/material/0.174267730247144261640329585.425.png'
          "
          alt="avatar"
        />
      </div>
    </div>
  </div>
</template>

<script>
import uploadFile from "@/utils/upload_to_cos.js";
export default {
  data() {
    return {
      show: this.visible,
      fileList: [],
      loading: false,
      imageUrl: this.customer.head_img,
	  isCreateAccount:false,
      mCustomer: {
        last_name: "",
        first_name: "",
        enterprise: "",
        address: "",
        street: "",
        street2: "",
        city: "",
        country: "",
        tel: "",
        user_mobile: "",
        user_email: "",
        head_img: "",
        site: "",
        tax: "",
        tag: "",
        province: "",
        vat_number: "",
		is_build_account:0
      },
    };
  },
  methods: {
    translate(c) {
      const {} = c;
      return {};
    },
    close() {
      this.show = false;
      this.$emit("close", this.mCustomer);
    },
    submit() {
      this.show = false;
      //this.$emit("close", this.mCustomer);
	  this.$emit("submit");
    },
    handleImgChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map((file) => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });

      this.fileList = fileList;
      if (this.fileList.length == 0) {
        this.$emit("delete");
        return;
      }
      if (this.fileList[0].status == "done") {
        this.$emit("done", this.fileList);
      }
    },
    customRequest({ file, onSuccess, onError, onProgress }) {
      let process = {
        percent: 1,
      };
      let timeId = setInterval(() => {
        if (process.percent < 100) {
          process.percent += 1;
          onProgress(process);
        } else {
          process.percent = 1;
        }
      }, 100);
      uploadFile([file], "material", (file) => {
        let process = {
          percent: 100,
        };
        onSuccess();
        onProgress(process);
        this.imageUrl = file[0];
        this.mCustomer.head_img = file[0];
        clearInterval(timeId);
      });
    },
	hangdleChange(){
		this.mCustomer.is_build_account = this.isCreateAccount?1:0;
	}
  },
  created() {
    this.mCustomer = this.customer;
  },

  watch: {
    visible(val) {
      this.show = val;
    },
    mCustomer: {
      handler(val) {
        this.$emit("change", val);
      },
      deep: true,
    },
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    edit: {
      type: Boolean,
      default: false,
    },
    customer: {
      type: Object,
      default: () => ({
        last_name: "",
        first_name: "",
        enterprise: "",
        address: "",
        street: "",
        street2: "",
        city: "",
        country: "",
        tel: "",
        user_mobile: "",
        user_email: "",
        head_img: "",
        site: "",
        tax: "",
        tag: "",
        province: "",
        vat_number: "",
      }),
    },
  },
};
</script>

<style lang="less" scoped>
.customer-detail {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  position: relative;
  min-width: 690px;
  .header {
    display: flex;
    justify-content: flex-end;
    height: 55px;
    border-bottom: 1px solid #d6d6d6;
    padding: 0 0;
    .sell-volume {
      width: 180px;
      height: 100%;
      border-left: 1px solid #d6d6d6;
      border-right: 1px solid #d6d6d6;
    }
    .settlement {
      width: 180px;
      height: 100%;
      display: flex;
      padding-right: 20px;
      justify-content: end;
      align-items: center;
      .el-icon-close {
        font-size: 20px;
      }
    }
  }
  .company-name {
    font-size: 30px;
    height: 200px;
    padding-top: 64px;
    padding-left: 40px;
    font-weight: 600;
    position: relative;
    > .ant-input {
      font-size: 32px;
      height: 40px;
      width: 400px;
      padding: 8px 4px;
    }
    .contact {
      display: flex;
      margin-top: 16px;
      span {
        font-size: 20px;
        font-weight: 500;
      }
      .ant-input {
        margin-right: 16px;
        width: 193px;
        font-size: 19px;
        font-weight: 600;
      }
    }
    .ant-input {
      font-size: 32px;
      height: 40px;
    }
  }
  .customer-avatar {
    position: absolute;
    top: 85px;
    right: 44px;
    width: 128px;
    height: 128px;
    display: flex;
    justify-content: center;
    align-items: center;
    .avatar-uploader > .ant-upload {
      width: 128px;
      height: 128px;
    }
    .ant-upload-select-picture-card i {
      font-size: 32px;
      color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
      margin-top: 8px;
      color: #666;
    }
  }
  .customer-avatar.border {
    border: 1px solid #ccc;
  }
  .ant-input {
    border: none;
    border-bottom: 1px solid #000;
    border-radius: 0;
    transition: all ease 0.5s;
    width: 56%;
    font-weight: 700;
  }
  .ant-input:focus {
    box-shadow: none;
    border-bottom: 1px solid var(--primaryColor);
  }
  .info {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 700;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 56px;

    .label {
      width: 180px;
      display: inline-block;
      font-weight: 700;
    }
    .row {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 12px;
    }
    .col {
      height: 30px;
      padding: 4px 8px;
      display: flex;
      align-items: center;
    }
  }
  .footer {
    padding-left: 40px;
    > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
      background: #fff;
      padding: 16px;
    }
    .ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab {
      margin-right: 0;
    }
    .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
      border-top: 1px solid var(--primaryColor);
      background: #fff;
    }
  }
  .avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.mask {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: fixed;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
}
.body {
  position: absolute;
  z-index: 999;
  top: 100px;
  width: 1000px;
}
.customer-detail-modal {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
</style>
